import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import CommonTable from "../../components/table/CommonTable";
import ProposalForm from "./ProposalForm";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import Store from 'app/utils/Store'
import moment from "moment-timezone";
import "./Proposal.css";
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { Tooltip } from '@material-ui/core';
import { Button } from "@material-ui/core";
import { toast } from 'react-toastify';
import ProposalView from "./proposalView.js/ProposalView";
import ProposalFinances from "./ProposalFinances";
import ProposalProductList from "./proposalProducts/ProposalProductsList";
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";
import { useLocation } from "react-router";
import CustomFiltersProposals from "./CustomFiltersProposals";
import { eventBus } from "app/utils/EventListener";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
export default function ProposalList() {
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0)
  const [valuesProposals, setValuesProposals] = useState([])
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});
  const [params, setParams] = useState([])

  const [proposalSelected, setProposalSelected] = useState([])
  const [proposalProducts, setProposalProducts] = useState([])
  const [proposalValues, setProposalValues] = useState([])
  const [selectedLine, setSelectedLine] = useState(undefined)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [proposalsClicked, setProposalsClicked] = useState([])
  const [openChangeStatus, setOpenChangeStatus] = useState(false)
  const [openChangeTags, setOpenChangeTags] = useState(false)

  const [openCustomFilter, setOpenCustomFilter] = useState(false)
  const [agencies, setAgencies] = useState([])
  const [clients, setClients] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [squares, setSquares] = useState([])
  const [products, setProducts] = useState([])
  const [status, setStatus] = useState([])
  const [users, setUsers] = useState([])
  const [tags, setTags] = useState([])
  const [justifies, setJustifies] = useState([])
  const [groups, setGroups] = useState([])
  const [idCustomFieldSelected, setIdCustomFieldSelected] = useState(0)
  const [customFilters, setCustomFilters] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({})

  const monthList = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    getCustomFilters()
    axios.post(
      Constants.APIEndpoints.PROPOSAL + "/getallgeneraldata", {
      entities: ['products', 'params', 'agencies', 'users', 'clients', 'vehicles', 'squares', 'status', 'justifies', 'tags', 'groups']
    })
      .then((res) => {
        setProducts(res.data.products)
        setParams(res.data.params)
        setAgencies(res.data.agencies)
        setUsers(res.data.users)
        setClients(res.data.clients)
        setVehicles(res.data.vehicles)
        setSquares(res.data.squares)
        setProducts(res.data.products)
        setStatus(res.data.status)
        setJustifies(res.data.justifies)
        setTags(res.data.tags);
        setGroups(res.data.groups)
      })

    const filtersLocal = localStorage.getItem('filters')
    if (filtersLocal) {
      const filters = JSON.parse(filtersLocal)
      setSelectedFilter(filters)
      setIdCustomFieldSelected(filters.id_custom_filters_proposals)
    }

    openFromActivityHistory()
  }, [])

  useEffect(() => {
    eventBus.on('openproposals', (data) => {
      console.log('data', data)
      openFromActivityHistory(data)
    })
  }, [])

  useEffect(() => {
    data.map(d => {
      d.number = d.is_imported ? `${d.number}*` : d.number
      d.month = monthList[d.month_sell]
      d.month_placement = monthList[d.month_placement]
    })
  }, [data])

  const openFromActivityHistory = (newState) => {
    if (!newState) {
      newState = state
    }
    if (newState) {
      if (newState.mode == 'view') {
        openView(newState.fk_id_proposals)
      } else {
        openEdit(newState.fk_id_proposals)
      }
    }
  }

  const onAdd = () => {
    setValues({})
    setPage("add");
  };

  const openView = (id_proposals) => {
    const notification = toast("Buscando informações");
    axios.post(Constants.APIEndpoints.PROPOSAL + '/getSingleProposal', { id_proposals: id_proposals }).then(res => {
      toast.dismiss(notification)
      setProposalSelected(res.data)
      setPage('view')
      setProposalSelected(res.data)
    })
  }

  const openEdit = (id_proposals, duplicate) => {
    const notification = toast("Buscando informações");
    axios.post(Constants.APIEndpoints.PROPOSAL + '/getSingleProposal', { id_proposals: id_proposals }).then(res => {
      toast.dismiss(notification)
      let values = res.data
      values.duplicate = duplicate
      setValues(res.data)
      setPage('add')
    })
  }

  useEffect(() => {
    getData();
    axios
      .get(
        Constants.APIEndpoints.STATUS + "/getAllStatus")
      .then((res) => {
        setStatus(res.data[0])
      })

  }, []);

  const deleteProposal = () => {
    const data = { id_proposals: proposalSelected.id_proposals }
    axios.post(Constants.APIEndpoints.PROPOSAL + "/deleteProposal", data).then((res) => {
      setDeleteDialog(false)
      getData();
    });

  };

  const getDataProducts = (id_proposals) => {
    axios.get(Constants.APIEndpoints.PROPOSAL + "/getproposalproducts/" + id_proposals).then((res) => {
      setProposalProducts(res.data);
    });
    setTimeout(() => {
      axios.get(Constants.APIEndpoints.PROPOSAL + '/getproposalvalues/' + id_proposals).then(res => {
        setProposalValues(res.data[0])
      })
    }, 500)
  };


  const openFinances = (id_proposals) => {
    const notification = toast("Buscando informações");
    axios.get(Constants.APIEndpoints.PROPOSAL + '/getproposalfinances/' + id_proposals).then(res => {
      toast.dismiss(notification)
      let finances = res.data
      if (finances.comission_value == null) {
        const taxes = finances.net_value_approved * (finances.taxes_mosaic || 0 / 100)
        const comission = (finances.comission_mosaic || 0) / 100
        const comission_value = (finances.net_value_approved - (taxes / 100)) * comission
        finances.comission_value = comission_value
      }
      if (finances.taxes_nf == null) {
        const taxes_nf = finances.comission_value * (finances.taxes_nf_percent / 100)
        finances.taxes_nf = parseFloat(taxes_nf).toFixed(2)
      }
      if (finances.dt_received_vehicle) {
        finances.dt_received_vehicle = moment(finances.dt_received_vehicle).format('YYYY-MM-DD')
      }
      if (finances.dt_received_mosaic) {
        finances.dt_received_mosaic = moment(finances.dt_received_mosaic).format('YYYY-MM-DD')
      }
      if (finances.nf_due_date) {
        finances.nf_due_date = moment(finances.nf_due_date).format('YYYY-MM-DD')
      }
      if (finances.dt_received_comission_executive) {
        finances.dt_received_comission_executive = moment(finances.dt_received_comission_executive).format('YYYY-MM-DD')
      }

      setValues(finances)
      setPage('finances')
    })
  }

  const getData = (filter = {}, limit = 25, page = 0) => {
    const toastData = toast('Atualizando dados.')
    const filtersLocal = localStorage.getItem('filters')
    if (!filter.custom && filtersLocal) {
      filter.custom = JSON.parse(filtersLocal)
    }

    let offset = limit * page
    axios.post(`${Constants.APIEndpoints.PROPOSAL}/getAllProposals?limit=${limit}&offset=${offset}`, { ...filter }).then((res) => {
      const data = res.data.proposals.map(r => ({
        dt_emission_: r.dt_emission ? moment(r.dt_emission).format('DD/MM/YYYY') : '',
        dt_cad_: moment(r.dt_cad).format('DD/MM/YYYY'),
        dt_start_: r.dt_start ? moment(r.dt_start).format('DD/MM/YYYY') : '',
        dt_end_: r.dt_end ? moment(r.dt_end).format('DD/MM/YYYY') : '',
        approved_gross_value_: parseFloat((r.approved_gross_value || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        net_value_approved_: parseFloat((r.net_value_approved || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        comission_value_: parseFloat((r.comission_value || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        received_value_: parseFloat((r.received_value || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        taxes_: r.taxes + '%',
        taxes_nf_: parseFloat((r.taxes_nf || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        dt_received_vehicle_: r.dt_received_vehicle ? moment(r.dt_received_vehicle).format('DD/MM/YYYY') : '',
        dt_received_mosaic_: r.dt_received_mosaic ? moment(r.dt_received_mosaic).format('DD/MM/YYYY') : '',
        ...r
      }))
      setData(data);
      setTotalData(res.data.total)
      setValuesProposals(res.data.values[0])
      toast.dismiss(toastData)
      toast('Dados atualizados.', { type: toast.TYPE.SUCCESS })
    });
  };

  const getCustomFilters = () => {
    axios.get(Constants.APIEndpoints.PROPOSAL + '/getcustomfilters').then(res => {
      setCustomFilters(res.data)
    })
  }

  const handleClickRow = (row) => {
    const index = proposalsClicked.findIndex(p => p.id_proposals == row.id_proposals)
    if (index > -1) {
      setProposalsClicked(prev => prev.filter(p => p.id_proposals !== row.id_proposals))
    } else {
      setProposalsClicked(prev => [...prev, row])
    }
  }
  const columns = [
    {
      Header: 'Nº PI/PP',
      accessor: "number",
      className: "font-medium",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: 'Etiquetas',
      accessor: 'tags',
      Cell: ({ row }) => (
        <div>
          {(row.original.tags || []).map(tag => (
            <div style={{
              backgroundColor: tag.color, height: 20, width: 80
              , margin: 4, borderRadius: 10, display: 'flex', color: 'white', fontSize: 10,
              justifyContent: 'center', alignItems: 'center'
            }}>{tag.name}</div>
          ))}
        </div>
      )
    },
    {
      Header: "Time",
      accessor: "team",
      className: "font-medium",
      sortable: true,
      disableSortBy: true
    },

    {
      Header: "Veículo",
      accessor: "vehicle",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Representante",
      accessor: "sponsor_vehicle",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Grupo",
      accessor: "groupname",
      sortable: false,
      disableSortBy: true,
    },
    {
      Header: "Nº NF",
      accessor: "nf",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Cliente",
      accessor: "client",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Segmento de Mercado",
      accessor: "market_segment",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Data de Início",
      accessor: "dt_start_",
      sortable: true,
      disableSortBy: true
    }, {
      Header: "Data de Término",
      accessor: "dt_end_",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Agência",
      accessor: "agency",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Status",
      accessor: "status",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Substatus",
      accessor: "substatus",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Justificativa",
      accessor: "justify",
      sortable: true,
      Cell: ({ row }) => (
        <div>
          {(row.original.justifies || []).map(j => j.name).join(', ')}
        </div>
      )
    },
    {
      Header: "Mês de venda",
      accessor: "month",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Mês de veiculação",
      accessor: "month_placement",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Praça",
      accessor: "square",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Responsável",
      accessor: "responsable",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Criador",
      accessor: "creator",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Data Ganho",
      accessor: "dt_emission_",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Data de Criação",
      accessor: "dt_cad_",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Valor de Comissão",
      accessor: "comission_value_",
      sortable: true,
      disableSortBy: true,
      filter: true
    },
    {
      Header: "Valor recebido",
      accessor: "received_value_",
      sortable: true,
      disableSortBy: true,
      filter: true
    },
    {
      Header: "Imposto",
      accessor: "taxes_",
      sortable: true,
      disableSortBy: true,
      filter: true
    },
    {
      Header: "VR. Imposto Emissão de NF",
      accessor: "taxes_nf_",
      sortable: true,
      disableSortBy: true,
      filter: true
    },
    {
      Header: "Nº NF Representante",
      accessor: "nf_mosaic",
      sortable: true,
      disableSortBy: true,
      filter: true
    },
    {
      Header: "Data de Recebimento do Veículo",
      accessor: "dt_received_vehicle_",
      sortable: true,
      disableSortBy: true,
      filter: true
    }, {
      Header: "Data de Recebimento do Representante",
      accessor: "dt_received_mosaic_",
      sortable: true,
      disableSortBy: true,
      filter: true
    },
    {
      Header: "Valor Bruto",
      accessor: "approved_gross_value_",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Valor Líquido",
      accessor: "net_value_approved_",
      sortable: true,
      disableSortBy: true
    },
    {
      Header: "Campanha",
      accessor: "campaign",
      className: "font-medium",
      sortable: true,
      disableSortBy: true
    },
    {
      id: "action",
      width: 128,
      sortable: false,
      Cell: ({ row }) => (
        <div className="flex items-center">
          {access.proposal_finances ? (
            <Tooltip title={<h5>Abrir o financeiro</h5>} placement="right">

              <IconButton
                onClick={(ev) => {
                  openFinances(row.original.id_proposals)

                }}
              >
                <Icon>money</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
          {params.use_new_products_screen_proposals == true ? (
            <Tooltip title={<h5>Editar os Produtos</h5>} placement="right">

              <IconButton
                onClick={(ev) => {
                  setProposalSelected(row.original)
                  setPage('products')
                }}
              >
                <Icon>shopping_cart</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
          {access.proposal_read ? (
            <Tooltip title={<h5>Visualizar Detalhes</h5>} placement="right">

              <IconButton
                onClick={(ev) => {
                  openView(row.original.id_proposals)

                }}
              >
                <Icon>remove_red_eye</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
          {access.proposal_create ? (
            <Tooltip title={<h5>Clonar Negócio</h5>} placement="right">

              <IconButton
                onClick={(ev) => {
                  openEdit(row.original.id_proposals, true)
                }}
              >
                <Icon>content_copy</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
          {access.proposal_update ? (
            <Tooltip title={<h5>Editar Negócio</h5>} placement="right">

              <IconButton
                onClick={(ev) => {
                  openEdit(row.original.id_proposals, false)
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
          {access.proposal_delete ? (
            <Tooltip title={<h5>Excluir Negócio</h5>} placement="right">

              <IconButton
                onClick={(ev) => {
                  setProposalSelected(row.original)
                  setDeleteDialog(true)
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      ),
    },
  ]

  if (params.use_new_products_screen_proposals == true) {
    columns.unshift(
      {
        id: "opensub",
        visible: true,
        width: 28,
        noSearchable: true,
        Cell: ({ row }) => (
          <Tooltip title={<h5>Visualizar Produtos</h5>} placement="right">
            <IconButton
              onClick={(ev) => {
                if (selectedLine == row.id) {
                  setSelectedLine(undefined)
                } else {
                  getDataProducts(row.original.id_proposals)
                  setSelectedLine(row.id)
                }
              }}
            >
              <Icon>{selectedLine !== row.id ? 'arrow_downward' : 'arrow_upward'}</Icon>
            </IconButton >
          </Tooltip>
        )
      })
  }
  const underHeaderSubTable = (
    <div style={{ padding: 10 }}>
      <p style={{ fontSize: 14 }}>Valor Bruto: <strong>{parseFloat(proposalValues?.approved_gross_value || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
      <p style={{ fontSize: 14 }}>Valor Líquido: <strong>{parseFloat(proposalValues?.net_value_approved || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
    </div>
  )

  const underHeader = (
    <div style={{ padding: 10, display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <p style={{ fontSize: 14 }}>Valor Bruto: <strong>{parseFloat(valuesProposals?.approved_gross_value || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
        <p style={{ fontSize: 14 }}>Valor Líquido: <strong>{parseFloat(valuesProposals?.net_value_approved || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
      </div>
      {selectedFilter && Object.keys(selectedFilter).length > 0 && (
        <p style={{ fontSize: 14 }}>Filtro Ativo: <strong>{selectedFilter.name}</strong></p>
      )}
    </div>
  )
  let widthDiv = window.innerWidth
  const divTableContainer = document.getElementById("tableContainer");
  if (divTableContainer) {
    widthDiv = divTableContainer.offsetWidth
  }

  const subTable = (
    <div style={{ width: widthDiv, overflowX: 'auto' }}>
      <CommonTable
        miniVersion={true}
        underHeader={underHeaderSubTable}
        noHeader={true}
        fullWhite={true}
        data={proposalProducts}
        showEditButton={true}
        columns={[
          {
            Header: "Produto",
            accessor: "product_name",
            className: "font-medium",
            sortable: true,
            noSearchable: true,
          },
          {
            Header: "Quantidade Contratada",
            accessor: "quantity_hired",
            sortable: true,
            noSearchable: true,
          },
          {
            Header: "Quantidade Entregue",
            accessor: "quantity_delivered",
            sortable: true,
            noSearchable: true,
          },
          {
            Header: "Porcentagem entregue",
            accessor: "percent_delivered",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {row.original.percent_delivered}%
              </div>
            )
          },
          {
            Header: "Data de Início",
            accessor: "dt_start",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {moment(row.original.dt_start).format('DD/MM/YYYY')}
              </div>
            )
          },
          {
            Header: "Data de Término",
            accessor: "dt_end",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {moment(row.original.dt_end).format('DD/MM/YYYY')}
              </div>
            )
          },
          {
            Header: "Preço",
            accessor: "price",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div >
                {(row.original.price || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </div>
            )
          },
          {
            Header: "Executivo",
            accessor: "executive",
            sortable: true,
            noSearchable: true,
          },
          {
            Header: "Status",
            accessor: "status_name",
            sortable: true,
            noSearchable: true,
          },
          {
            Header: "Substatus",
            accessor: "substatus_name",
            sortable: true,
            noSearchable: true,
          },
          {
            Header: "Custos da Plataforma",
            accessor: "platform_costs",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {parseFloat(row.original.platform_costs || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </div>
            )
          },
          {
            Header: "Custos Extras",
            accessor: "extras_costs",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div >
                {parseFloat(row.original.extras_costs || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </div>
            )
          },
          {
            Header: "Porcentagem de Custos",
            accessor: "percent_costs",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {row.original.percent_costs}%
              </div>
            )
          },
          {
            Header: "Investimento",
            accessor: "investment",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {parseFloat(row.original.investment || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </div>
            )
          },
          {
            Header: "Perdas",
            accessor: "lost",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {parseFloat(row.original.lost || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </div>
            )
          },
          {
            Header: "Total Bruto",
            accessor: "gross_billed",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {parseFloat(row.original.gross_billed || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </div>
            )
          },
          {
            Header: "Total Líquido",
            accessor: "net_billed",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {parseFloat(row.original.net_billed || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </div>
            )
          },
          {
            Header: "Mês recebido",
            accessor: "month_received",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {row.original.month_received > 0 ? monthList[row.original.month_received] : ''}
              </div>
            )
          },
          {
            Header: "Observação",
            accessor: "observation",
            sortable: true,
            noSearchable: true,
            Cell: ({ row }) => (
              <div>
                {(row.original.observation || '').replace(/<[^>]*>?/gm, '')}
              </div>
            )
          },
          {
            Header: "Nota Fiscal",
            accessor: "nf",
            sortable: true,
            noSearchable: true,
          },
          {
            Header: "Status de envio",
            accessor: "delivered_status_name",
            sortable: true,
            noSearchable: true,
          }
        ]}

      />
    </div>
  )

  const headerContent = (
    <div>
      {proposalsClicked.length > 0 && (
        <div style={{ display: 'flex', width: 340, zIndex: 100, position: 'relative' }}>
          <div className="changeStatusButton"
            onClick={() => setOpenChangeStatus(true)}>
            Alterar Status
          </div>
          <div className="changeStatusButton"
            onClick={() => setOpenChangeTags(true)}>
            Adicionar Etiquetas
          </div>
        </div>
      )}
    </div>
  )

  const rightSide = (
    <div style={{ width: '60%', display: 'flex', justifyContent: 'end' }}>
      <Button
        variant="contained"
        color="white"
        onClick={() => setOpenCustomFilter(true)}
      >
        <span className="hidden sm:flex">Filtros Personalizados</span>
      </Button>
    </div>
  )

  const changeMultiStatus = (values) => {
    values.proposals = proposalsClicked.map(p => p.id_proposals)
    axios.post(Constants.APIEndpoints.PROPOSAL + '/changemultistatus', values).then(res => {
      if (res.data.length > 0) {
        toast(`Os seguintes negócios não foram atualizados por não seus dados obrigatórios preenchidos: ${res.data.join(', ')}`, { type: toast.TYPE.ERROR })
      }
      getData()
      setProposalsClicked([])
      setOpenChangeStatus(false)
    })
  }

  const changeMultiTags = (values) => {
    values.proposals = proposalsClicked.map(p => p.id_proposals)
    axios.post(Constants.APIEndpoints.PROPOSAL + '/changemultitags', values).then(res => {
      getData()
      setProposalsClicked([])
      setOpenChangeTags(false)
    })
  }
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    >
      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar esse Negócio?" cancel={() => setDeleteDialog(false)} confirm={deleteProposal} />
      ) : null}

      {openCustomFilter == true && (
        <CustomFiltersProposals
          open={openCustomFilter}
          onClose={() => {
            setOpenCustomFilter(false)
            // setSingleContact([])
          }}
          getData={getData}
          agencies={agencies}
          clients={clients}
          vehicles={vehicles}
          squares={squares}
          products={products}
          status={status}
          users={users}
          tags={tags}
          justifies={justifies}
          idCustomFieldSelected={idCustomFieldSelected}
          setIdCustomFieldSelected={setIdCustomFieldSelected}
          customFilters={customFilters}
          getCustomFilters={getCustomFilters}
          groups={groups}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        // values={singleContact}
        // getData={getData}
        />
      )}

      <CommonDialog
        open={openChangeStatus}
        onClose={() => setOpenChangeStatus(false)}
        title='Trocar status'
        fullWidth={true}
        maxWidth='md'
      >
        <CommonForm
          fields={[
            {
              col: 12,
              type: "select",
              name: "fk_id_status",
              label: "Status",
              options: status.filter(s => s.sector == 'COMERCIAL').map(st => {
                return {
                  value: st.id_status,
                  label: st.name,
                  color: 'black'
                }
              }),
            }
          ]}
          buttonBellow={250}
          onSubmit={changeMultiStatus}
        />
      </CommonDialog>

      <CommonDialog
        open={openChangeTags}
        onClose={() => setOpenChangeTags(false)}
        title='Trocar Tags'
        fullWidth={true}
        maxWidth='md'
      >
        <CommonForm
          fields={[
            {
              col: 12,
              type: "multiselect",
              name: "tags",
              label: "Tags",
              options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color }))

            }
          ]}
          buttonBellow={250}
          onSubmit={changeMultiTags}
        />
      </CommonDialog>

      {page == "list" ? (
        <div>
          <CommonTable
            id="proposal_list"
            underHeader={underHeader}
            columns={columns}
            selectedLine={selectedLine}
            subTable={subTable}
            totalData={totalData}
            data={data}
            noSearch={true}
            rightSide={rightSide}
            icon="people"
            pagination={getData}
            newText="Adicionar Novo Negócio"
            onAdd={access.proposal_create ? onAdd : null}
            headerTitle="Negócios"
            filterColumnsHeader={access.proposal_finances == true}
            rowsClicked={proposalsClicked}
            primaryKey={'id_proposals'}
            headerContent={headerContent}
            onRowClick={(ev, row) => {
              if (row) {
                handleClickRow(row.original)
              }
            }}
          />
        </div>
      ) : page == 'view' ? (
        <ProposalView proposal={proposalSelected} setPage={setPage} />
      ) : page == 'finances' ? (
        <ProposalFinances proposal={values} setPage={setPage} />
      ) : page == 'products' ? (
        <ProposalProductList params={params} onBack={setPage} vehicle={proposalSelected.fk_id_vehicle} id_proposals={proposalSelected.id_proposals} />
      ) : (
        <ProposalForm
          params={params}
          setProposalSelected={setProposalSelected}
          values={values}
          setPage={setPage}
          getData={getData}
          agencies={agencies}
          clients={clients}
          vehicles={vehicles}
          squares={squares}
          products={products}
          status={status}
          users={users}
          tags={tags}
          justifies={justifies}
        />
      )}

    </motion.div>
  );
}

