import { motion } from "framer-motion";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import ClientForm from "./ClientForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";

import CommonView from '../../components/commonView/CommonView'
import CommonDialog from "app/components/dialog/CommonDialog";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { Badge, Button } from "@material-ui/core";
import CommonForm from "app/components/form/CommonForm";
import ListClientsLeads from "../newLeads/Lists/ListClientsLeads";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
function ClientList(props) {
  const dispatch = useDispatch();
  // const user = useSelector(({ contactsApp }) => contactsApp.user);
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});
  const [approved, setApproved] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [clientsToSubstitute, setClientsToSubstitute] = useState([])
  const [singleClient, setSingleClient] = useState([])
  const [clientDialog, setClientDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  let [clientSelected, setClientSelected] = useState([])
  const [totalClientApprove, setTotalClientApprove] = useState(0)
  const [approveDialog, setApproveDialog] = useState(false)
  const [clientToReject, setClientToReject] = useState(0)
  const [openConfirmRejectDialog, setOpenConfirmRejectDialog] = useState(false)
  let agencies_ = []


  useEffect(() => {
    axios
      .get(
        Constants.APIEndpoints.CLIENT + "/getAllClients")
      .then((res) => {
        agencies_ = res.data
      })
    getTotalClientsApprove()

  }, [])

  const getTotalClientsApprove = () => {
    axios
      .post(
        Constants.APIEndpoints.CLIENT + "/getAllClients", { approve: true })
      .then((res) => {
        setTotalClientApprove(res.data.length)
      })
  }



  const approveClient = () => {
    axios.post(Constants.APIEndpoints.CLIENT + '/approveclient', { id_client: singleClient.id_client }).then(res => {
      getData(true)
      setApproveDialog(false)
    })
  }

  const rejectClient = () => {
    axios.post(Constants.APIEndpoints.CLIENT + '/rejectclient', { id_client: singleClient.id_client, newClient: clientToReject }).then(res => {
      getData(true)
      setOpenRejectDialog(false)
      getTotalClientsApprove()
      setOpenConfirmRejectDialog(false)
    })
  }

  const openRejectClientDialog = (id_client) => {
    setClientToReject(id_client)
    setOpenConfirmRejectDialog(true)
  }

  const openRejectClient = () => {
    axios
      .post(
        Constants.APIEndpoints.CLIENT + "/getAllClients", { approve: false })
      .then((res) => {
        setClientsToSubstitute(res.data)
        setOpenRejectDialog(true)
      })
  }

  const columns = useMemo(
    () => [

      {
        Header: "Nome Fantasia",
        accessor: "fancy_name",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "Prazo",
        accessor: "time",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "Razão Social",
        accessor: "company_name",
        sortable: true,
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
        sortable: true,
      },
      {
        Header: "Contato",
        accessor: "contact",
        sortable: true,
      },
      {
        Header: "Grupo",
        accessor: "fk_id_group",
        sortable: true,
      },
      {
        Header: "Segmento de Mercado",
        accessor: "market_segment",
        sortable: true,
      },
      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {['admin', 'subadmin'].includes(logged_user.role) && row.original.status == 'Approve' ? (
              <IconButton
                onClick={(ev) => {
                  setApproveDialog(true)
                  setSingleClient(row.original);
                }}
              >
                <Icon>check</Icon>
              </IconButton>
            ) : null}
            {['admin', 'subadmin'].includes(logged_user.role) && row.original.status == 'Approve' ? (
              <IconButton
                onClick={(ev) => {
                  setSingleClient(row.original)
                  openRejectClient()
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            ) : null}
            {access.client_read ? (
              <IconButton
                onClick={(ev) => {
                  viewClient(row.original);
                }}
              >
                <Icon>remove_red_eye</Icon>
              </IconButton>
            ) : null}
            {access.client_update ? (
              <IconButton
                onClick={(ev) => {
                  setValues(row.original)
                  setPage('add')
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            ) : null}
            {access.client_delete ? (

              <IconButton
                onClick={(ev) => {
                  setClientSelected(row.original)
                  setDeleteDialog(true)
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            ) : null}
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const onAdd = () => {
    setValues({})
    setPage("add");
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteClient = (id_client) => {
    const data = { id_client: clientSelected.id_client }
    axios.post(Constants.APIEndpoints.CLIENT + "/deleteClient", data).then((res) => {
      setDeleteDialog(false)
      getData();

    });
  };


  const viewClient = (client) => {

    setClientDialog(true)
    let data = [
      {
        col: 12,
        label: 'NOME FANTASIA',
        value: client.fancy_name
      },
      {
        col: 12,
        label: 'RAZÃO SOCIAL',
        value: client.company_name
      },
      {
        col: 4,
        label: 'CNPJ',
        value: client.cnpj
      },

      {
        col: 8,
        label: 'CONTATO',
        value: client.contact
      },
      {
        col: 6,
        label: 'TELEFONE',
        value: client.phone
      },
      {
        col: 6,
        label: 'EMAIL',
        value: client.email
      },

      {
        col: 12,
        label: 'ENDEREÇO',
        value: client.address
      },
      {
        col: 6,
        label: 'AGÊNCIA',
        value: agencies_.filter(a => a.id_agency == client.fk_id_agency)[0] ? agencies_.filter(a => a.id_agency == client.fk_id_agency)[0].fancy_name : ''
      },

    ]

    setSingleClient(data)
  }


  const getData = (approve = false) => {
    setApproved(approve)
    axios.post(Constants.APIEndpoints.CLIENT + `/getAllClients?`, { approve }).then((res) => {
      setData(res.data);
    });
  };

  const rightSide = (
    <div style={{ width: '20%', display: 'flex', justifyContent: 'center' }}>
      <Badge color="secondary" badgeContent={totalClientApprove} variant="standard" invisible={approved}>
        <Button
          variant="contained"
          color="white"
          onClick={() => getData(!approved)}
        >
          <span className="hidden sm:flex">{!approved ? 'Aprovações' : 'Aprovadas'}</span>
        </Button>
      </Badge>

    </div>
  )


  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    >
      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar esse Cliente?" cancel={() => setDeleteDialog(false)} onClose={() => setDeleteDialog(false)} confirm={deleteClient} />
      ) : null}
      {approveDialog ? (
        <ConfirmDialog title="Deseja aprovar esse Cliente?" cancel={() => setApproveDialog(false)} onClose={() => setApproveDialog(false)} confirm={approveClient} />
      ) : null}

      {openConfirmRejectDialog ? (
        <ConfirmDialog title="Deseja substituir por esse cliente?" cancel={() => setOpenConfirmRejectDialog(false)} onClose={() => setOpenConfirmRejectDialog(false)} confirm={rejectClient} />
      ) : null}

      <ListClientsLeads
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        clients={clientsToSubstitute}
        agencies={[]}
        selectItem={(id) => openRejectClientDialog(id)}
        noCreate={true}
      />

      <CommonDialog
        open={clientDialog}
        onClose={() => setClientDialog(false)}
        title="Ver Client"
        width="xl"
        print={true}
      >
        <CommonView dialog={true} data={singleClient} title="Ver Client" onBack={() => setPage('list')} />

      </CommonDialog>

      {page == "list" ? (
        <CommonTable
          rightSide={rightSide}
          id="client_list"
          columns={columns}
          data={data}
          icon="people"
          newText="Adicionar Novo Cliente"
          onAdd={access.client_create ? onAdd : null}
          headerTitle="Clientes"
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : (
        <ClientForm values={values} setPage={setPage} getData={getData} approved={approved} />
      )}
    </motion.div>
  );
}

export default ClientList;
